import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/github/workspace/node_modules/gatsby-theme-docz/src/base/Layout.js";
import "../style-index.css";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>Multi Program</h1>
    <p>
  {" "}
  <span>Administrators</span> <span>Coaches</span> <span>Entrepreneurs</span> <span>Mentors</span>
    </p>
    <p>{`When you run multiple programs in parallel you can have access to them in a finger click.`}</p>
    <h2 {...{
      "id": "create-a-new-program"
    }}>{`Create a New Program`}</h2>
    <p>{`As an Administrator you have the rights to create new programs.`}</p>
    <p>
  Browse to the top left, and click where is your company name and Program Name.
  In there you will have a drop-down with all the program and the option{" "}
  <span>+ Create New Program</span>.
    </p>
    <p>
  When clicking in <span>+ Create New Program</span> fill-in the following
  options:
    </p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Program Name:`}</strong>{` the name of your new program`}</li>
      <li parentName="ul"><strong parentName="li">{`Description:`}</strong>{` what is this program about.`}</li>
      <li parentName="ul"><strong parentName="li">{`Customizable Modules:`}</strong>{` What modules you wants to be enabled in your program`}</li>
      <li parentName="ul"><strong parentName="li">{`Other options:`}</strong><ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Slack button link:`}</strong>{` this will show a floating button in the application.`}</li>
          <li parentName="ul"><strong parentName="li">{`All Users:`}</strong>{` you can invite users already registered into your customer.`}</li>
        </ul></li>
    </ul>
    <h2 {...{
      "id": "browse-between-programs"
    }}>{`Browse between Programs`}</h2>
    <p>{`Any user invited to multiple programs will have the option to browse between programs.`}</p>
    <p>
  Browse to the top left, and click where is your company name and Program Name.
  In there you will have a drop-down with all the programs then click in the
  program that you want.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      